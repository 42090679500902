<template>
  <div
    :class="[
      'separator',
      {
        'separator--bottom-align': blok.align_to_bottom,
      },
    ]"
  >
    <hr
      v-editable="blok"
      :style="lineColor"
      :class="['separator-line', customMarginBottom, customMarginTop]"
    />
  </div>
</template>

<script lang="ts" setup>
import type { SbSeparatorStoryblok } from '@/types'
interface Props {
  blok: SbSeparatorStoryblok
}
const props = defineProps<Props>()

const lineColor = computed(() => {
  return `border-color: ${props.blok?.color.value}`
})
const customMarginBottom = computed(() => {
  return `mb-${props.blok?.margin_bottom}` ?? 'mb-0'
})
const customMarginTop = computed(() => {
  return `mt-${props.blok?.margin_top}` ?? 'mt-0'
})
</script>

<style lang="scss" scoped>
.separator {
  width: 100%;

  &--bottom-align {
    margin-top: auto;
  }
}

.separator-line {
  border: 0;
  height: 0;
  border-top: 1px solid var(--gray-300);
}
</style>
